import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/components/LoginView';
import RegisterView from '@/components/RegisterView';
import ForgotPasswordView from '@/components/ForgotPasswordView';
import MusicUploadView from '@/components/MusicUploadView';
import VisualizationView from '@/components/VisualizationView';

const routes = [
    {
      path: '/', 
      name: 'loginView', 
      component: LoginView
    },
    {
      path: '/register', 
      name: 'registerView', 
      component: RegisterView
    },
    {
      path: '/forgotPassword',
      name: 'forgotPasswordView',
      component: ForgotPasswordView
    },
    {
      path: '/musicUpload',
      name: 'musicUploadView',
      component: MusicUploadView,
      meta: { requiresAuth: true } // 需要登录才能访问该路由
    },
    {
      path: '/visualization',
      name: 'visualizationView',
      component: VisualizationView,
      meta: { requiresAuth: true } // 需要登录才能访问该路由
    }
  ]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // 判断用户是否登录，如果登录了则可以访问路由，否则跳转到登录页
  const isLogin = localStorage.getItem('token') ? true : false;
  if (to.meta.requiresAuth && !isLogin) {
    next('/');
  } else {
    next();
  }
})

export default router
