<template>
  <div class="register-container">
    <form @submit.prevent="register">
      <h2>注册</h2>
      <div class="form-group">
        <label for="email">邮箱</label>
        <input type="email" class="form-control" v-model="username" placeholder="Enter email" required>
      </div>
      <div class="form-group">
        <label for="username">昵称</label>
        <input type="text" class="form-control" v-model="name" placeholder="Enter username" required>
      </div>
      <div class="form-group">
        <label for="password">密码</label>
        <input type="password" class="form-control" v-model="password" placeholder="Password" required>
      </div>
      <div class="form-group">
        <label for="password">请重复密码</label>
        <input type="password" class="form-control" v-model="repassword" placeholder="Password" required>
      </div>
      <div class="form-group">
        <label for="gender">选择密保问题</label>
        <select class="form-control" v-model="sp_question" required>
          <option>你的姓名</option>
          <option>你母亲的姓名</option>
          <option>你父亲的姓名</option>
        </select>
      </div>
      <div class="form-group">
        <label for="sp_answer">密保答案</label>
        <input type="text" class="form-control" v-model="sp_answer" placeholder="Security protection" required>
      </div>
      <button type="submit" class="btn btn-primary">Register</button>
    </form>
  </div>
</template>
    
<style>
  .register-container {
    max-width: 500px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    font-weight: bold;
  }
  
  input[type="email"],
  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
  }
  
  button[type="submit"] {
    display: block;
    width: 100%;
    padding: 0.5rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
  }
  
  button[type="submit"]:hover {
    background-color: #0069d9;
  }
</style>

<script>
  import axios from 'axios';

  export default {
    name: 'RegisterView',
    data() {
      return {
        name: '',
        username: '',
        password: '',
        repassword: '',
        sp_question: '',
        sp_answer: '',
      }
    },
    methods: {
      register() {
        if (this.password != this.repassword) {
          this.password = ''
          this.repassword = ''
          alert("两次密码输入不相等");
          return ;
        }
        const data = {
          code: 0,
          msg: 'register',
          data: {
            name: this.name,
            username: this.username,
            password: this.password,
            spQuestion: this.sp_question,
            spAnswer: this.sp_answer
          }
        }
        axios.post('/api/user/register', data)
        .then(response => {
          // 处理成功的情况
          const data = response.data;
          if (data.code === 200) {
            // TODO：处理成功的逻辑
            // 登录成功，保存用户信息到本地存储
            localStorage.setItem('token', data.data)
            alert('注册成功，将返回登录界面');
            this.$router.push({path: "/"});
          } else {
            // TODO：处理失败的逻辑
            alert('失败：' + data.msg);
          }
        })
        .catch(error => {
          // TODO：处理失败的情况
          alert('请求失败：' + error);
        });
      }
    }
  }
</script>
  