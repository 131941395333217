<template>
  <div class="chart-container">
    <canvas id="chart01"></canvas>
  </div>
  <div class="chart-container">
    <canvas id="chart02"></canvas>
  </div>
  <div class="chart-container">
    <canvas id="chart03"></canvas>
  </div>
  <div class="chart-container">
    <canvas id="chart04"></canvas>
  </div>
  <button @click="showMore">Show More</button>
  <div v-show="show">
    <div class="chart-container">
      <canvas id="chart05"></canvas>
    </div>
    <div class="chart-container">
      <canvas id="chart06"></canvas>
    </div>
    <div class="chart-container">
      <canvas id="chart07"></canvas>
    </div>
  </div>
</template>

<style>
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50vh;
  margin: 0 auto;
}
</style>

<script>
import Chart from 'chart.js/auto';
import axios from 'axios';

export default {
  data() {
    return {
      lessConvTestAcc: [],
      moreConvTestAcc: [],
      lessLstmTestAcc: [],
      moreLstmTestAcc: [],
      smallTestAcc: [],
      largeTestAcc: [],
      largeTrainLoss: [],
      largeTestLoss: [],
      smallIndex: [],
      show: false,
      lessLstmTrainLoss: [],
      lessLstmTestLoss: [],
      moreLstmTrainLoss: [],
      moreLstmTestLoss: [],
      highLrTrainLoss: [],
      highLrTestLoss: [],
      lowLrTrainLoss: [],
      lowLrTestLoss: [],
      largeIndex: [],
    }
  },
  mounted() {
    axios.get('/api/visualization/01')
    .then(response => {
      // 处理成功的情况
      const data = response.data;
      if (data.code === 200) {
        // TODO：处理成功的逻辑
        const visData = data.data;
        console.log(visData);
        for (let i = 0; i < visData.length; i++) {
          this.lessConvTestAcc.push(visData[i].lessConvTestAcc);
          this.moreConvTestAcc.push(visData[i].moreConvTestAcc);
          this.lessLstmTestAcc.push(visData[i].lessLstmTestAcc);
          this.moreLstmTestAcc.push(visData[i].moreLstmTestAcc);
          this.smallTestAcc.push(visData[i].smallTestAcc);
          this.largeTestAcc.push(visData[i].largeTestAcc);
          this.largeTrainLoss.push(visData[i].largeTrainLoss);
          this.largeTestLoss.push(visData[i].largeTestLoss);
          this.smallIndex.push(i);
        }
        const canvas01 = document.getElementById('chart01');
        const canvas02 = document.getElementById('chart02');
        const canvas03 = document.getElementById('chart03');
        const canvas04 = document.getElementById('chart04');
        new Chart(canvas01, {
          type: 'line',
          data: {
            labels: this.smallIndex,
            datasets: [{
              label: 'less Conv',
              data: this.lessConvTestAcc,
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            }, {
              label: 'more Conv',
              data: this.moreConvTestAcc,
              fill: false,
              borderColor: 'rgb(255, 99, 132)',
              tension: 0.1
            }]
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: '卷积层增加前后对比',
                font: {
                  size: 25,
                  weight: 'bold'
                }
              }
            }
          }
        });
        new Chart(canvas02, {
          type: 'line',
          data: {
            labels: this.smallIndex,
            datasets: [{
              label: 'less LSTM',
              data: this.lessLstmTestAcc,
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            }, {
              label: 'more LSTM',
              data: this.moreLstmTestAcc,
              fill: false,
              borderColor: 'rgb(255, 99, 132)',
              tension: 0.1
            }]
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'LSTM层减少前后对比',
                font: {
                  size: 25,
                  weight: 'bold'
                }
              }
            }
          }
        });
        new Chart(canvas03, {
          type: 'line',
          data: {
            labels: this.smallIndex,
            datasets: [{
              label: 'small sample size',
              data: this.smallTestAcc,
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            }, {
              label: 'large sample size',
              data: this.largeTestAcc,
              fill: false,
              borderColor: 'rgb(255, 99, 132)',
              tension: 0.1
            }]
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: '数据增强前后对比',
                font: {
                  size: 25,
                  weight: 'bold'
                }
              }
            }
          }
        });
        new Chart(canvas04, {
          type: 'line',
          data: {
            labels: this.smallIndex,
            datasets: [{
              label: 'train loss',
              data: this.largeTrainLoss,
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            }, {
              label: 'test loss',
              data: this.largeTestLoss,
              fill: false,
              borderColor: 'rgb(255, 99, 132)',
              tension: 0.1
            }]
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: '模型表现',
                font: {
                  size: 25,
                  weight: 'bold'
                }
              }
            }
          }
        });
      } else {
        // TODO：处理失败的逻辑
        alert('失败：' + data.msg);
      }
    })
    .catch(error => {
      // TODO：处理失败的情况
      alert('请求失败：' + error);
    });
  },
  methods: {
    showMore() {
      axios.get('/api/visualization/02')
      .then(response => {
        // 处理成功的情况
        const data = response.data;
        if (data.code === 200) {
          // TODO：处理成功的逻辑
          const visData = data.data;
          console.log(visData);
          for (let i = 0; i < visData.length; i++) {
            this.lessLstmTrainLoss.push(visData[i].lessLstmTrainLoss);
            this.lessLstmTestLoss.push(visData[i].lessLstmTestLoss);
            this.moreLstmTestLoss.push(visData[i].moreLstmTestLoss);
            this.highLrTestLoss.push(visData[i].highLrTestLoss);
            this.lowLrTestLoss.push(visData[i].lowLrTestLoss);
            this.largeIndex.push(i);
          }
          const canvas05 = document.getElementById('chart05');
          const canvas06 = document.getElementById('chart06');
          const canvas07 = document.getElementById('chart07');
          new Chart(canvas05, {
            type: 'line',
            data: {
              labels: this.largeIndex,
              datasets: [{
                label: 'less LSTM',
                data: this.lessLstmTestLoss,
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
              }, {
                label: 'more LSTM',
                data: this.moreLstmTestLoss,
                fill: false,
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1
              }]
            },
            options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'LSTM减少前后对比',
                font: {
                  size: 25,
                  weight: 'bold'
                }
              }
            }
          }
          });
          new Chart(canvas06, {
            type: 'line',
            data: {
              labels: this.largeIndex,
              datasets: [{
                label: 'high learning rate',
                data: this.highLrTestLoss,
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
              }, {
                label: 'low learning rate',
                data: this.lowLrTestLoss,
                fill: false,
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1
              }]
            },
            options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: '学习率调整前后对比',
                font: {
                  size: 25,
                  weight: 'bold'
                }
              }
            }
          }
          });
          new Chart(canvas07, {
            type: 'line',
            data: {
              labels: this.largeIndex,
              datasets: [{
                label: 'Train Loss',
                data: this.lessLstmTrainLoss,
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1
              }, {
                label: 'Test Loss',
                data: this.lessLstmTestLoss,
                fill: false,
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1
              }]
            },
            options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: '模型表现',
                font: {
                  size: 25,
                  weight: 'bold'
                }
              }
            }
          }
          });
        } else {
          // TODO：处理失败的逻辑
          alert('失败：' + data.msg);
        }
      })
      .catch(error => {
        // TODO：处理失败的情况
        alert('请求失败：' + error);
      });
      this.show = true;
    },
  },
};
</script>
