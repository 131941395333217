<template>
    <div class="forgot-password-container">
      <form @submit.prevent="forgotPassword">
        <h2>忘记密码</h2>
        <div class="form-group">
            <label for="email">邮箱</label>
            <div class="input-group">
                <input type="email" class="form-control" v-model="username" placeholder="Enter email" required>
                <button class="btn btn-primary" type="button" @click="selectSq">查看密保问题</button>
            </div>
        </div>
        <div class="form-group">
            <label for="server-response">密保</label>
            <input type="text" class="form-control" :value="spQuestion" readonly>
        </div>
        <div class="form-group">
            <label for="verify">密保核验</label>
            <input type="text" class="form-control" placeholder="Security protection" required id="spAnswerInput">
        </div>
        <div class="form-group">
          <label for="password">密码</label>
          <input type="password" class="form-control" v-model="password" placeholder="Password" required>
        </div>
        <div class="form-group">
          <label for="password">请重复密码</label>
          <input type="password" class="form-control" v-model="repassword" placeholder="Password" required>
        </div>
        <button type="submit" class="btn btn-primary">Send Reset Link</button>
      </form>
    </div>
  </template>
  
  <style>
    .forgot-password-container {
      max-width: 500px;
      margin: 0 auto;
    }
    
    .form-group {
      margin-bottom: 1rem;
    }
    
    label {
      font-weight: bold;
    }
    
    input[type="email"] {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 0.25rem;
    }
    
    button {
      display: block;
      width: 100%;
      padding: 0.5rem;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 0.25rem;
    }
    
    button:hover {
      background-color: #0069d9;
    }
  </style>
  
  <script>
   import axios from 'axios';

    export default {
      name: 'ForgotPasswordView',
      data() {
        return {
          userId: '',
          username: '',
          spQuestion: '',
          spAnswer: '',
          password: '',
          repassword: '',
        }
      },
      methods: {
        selectSq() {
          const data = {
          code: -2,
          msg: 'forgetPassword',
          data: {
            username: this.username,
          }
        }
        axios.post('/api/user/forgotPassword', data)
        .then(response => {
          // 处理成功的情况
          const data = response.data;
          if (data.code === 200) {
            // TODO：处理成功的逻辑
            const user = data.data;
            this.userId = user["userId"];
            this.spQuestion = user["spQuestion"];
            this.spAnswer = user["spAnswer"];
          } else {
            // TODO：处理失败的逻辑
            alert('失败：' + data.msg);
          }
        })
        .catch(error => {
          // TODO：处理失败的情况
          alert('请求失败：' + error);
        });
        },
        forgotPassword() {
          const answer = document.getElementById('spAnswerInput').value;
          if (answer === null) {
            alert('核验错误！');
            return
          }
          if (answer === this.spAnswer) {
            if (this.password != this.repassword) {
              this.password = ''
              this.repassword = ''
              alert("两次密码输入不相等");
              return ;
            }
            const data = {
              code: 2,
              msg: 'updatePassword',
              data: {
                userId: this.userId,
                password: this.password,
              }
            }
            axios.post('/api/user/update', data)
            .then(response => {
              // 处理成功的情况
              const data = response.data;
              if (data.code === 200) {
                // TODO：处理成功的逻辑
                alert('密码修改成功，将返回登录界面');
                this.$router.push({path: "/"});
              } else {
                // TODO：处理失败的逻辑
                alert('失败：' + data.msg);
              }
            })
            .catch(error => {
              // TODO：处理失败的情况
              alert('请求失败：' + error);
            });
          }
        },
      }, 
    }
  </script>
  