/* eslint-disable no-mixed-spaces-and-tabs */
<template>
  <div class="login-container">
    <form @submit.prevent="login">
      <h2>Login</h2>
      <div class="form-group">
        <label>Email</label>
        <input type="email" v-model="username" placeholder="Enter email" required />
      </div>

      <div class="form-group">
        <label>Password</label>
        <input type="password" v-model="password" placeholder="Enter password" required />
      </div>

      <label class="button-container">
        <router-link to="/forgotPassword" class="left-btn">forget password?</router-link>
        <span class="spacer"></span>
        <router-link to="/register" class="right-btn">need to register?</router-link>
      </label>

      <button type="submit">Login</button>
    </form>
  </div>
  <div class="footer-inner">
    <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023013364号-1</a>
    <div style="width:300px;margin:0 auto; padding:20px 0;">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51011402000607" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="@/assets/ioc/备案图标.png" style="float:left;"/>
        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">川公网安备 51011402000607号</p>
      </a>
		</div>
  </div>
</template>
  
<style>
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .button-container {
      display: flex;
      justify-content: space-between;
  }

  .left-btn,
  .right-btn {
      text-decoration: none;
      color: rgba(0, 0, 255, 0.395);
      font-size: 14px;
      background-color: transparent;
      border: none;
      cursor: pointer;
  }

  body {
    /* background-image: url('../assets/images/front.jpg'); */
    background-size: cover;
    background-position: center;
    /* display: flex; */
    justify-content: center;
    align-items: center;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 300px;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
  }

  form h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  input[type="email"],
  input[type="password"] {
    font-size: 16px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  button[type="submit"] {
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  button[type="submit"]:hover {
    background-color: #449d44;
  }

  .footer-inner {
    border-bottom: none;
    background-color: #f5f8fa;
    margin: 0 auto;
    padding: 20px 0;
    position: relative;
}
</style>
  
<script>
  import axios from 'axios';

  export default {
    name: 'LoginView',
    data() {
      return {
        username: '',
        password: ''
      }
    },
    methods: {
      login() {
        const data = {
          code: -1,
          msg: 'login',
          data: {
            username: this.username,
            password: this.password
          }
        }
      
    // 发送 POST 请求
    axios.post('/api/user/login', data)
      .then(response => {
        // 处理成功的情况
        const data = response.data;
        if (data.code === 200) {
          // TODO：处理成功的逻辑
          // 登录成功，保存用户信息到本地存储
          localStorage.setItem('token', JSON.stringify(data.data));
          alert('成功：' + data.msg);
          if (data.data['roleId'] === 0) {
            this.$router.push({path: "/visualization"});
          } else {
            this.$router.push({path: "/musicUpload"});
          }
        } else {
          alert('失败：' + data.msg);
        }
      })
      .catch(error => {
        // TODO：处理失败的情况
        alert('请求失败：' + error);
      });
    }
  }
}
</script>
  