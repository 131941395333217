<template>
  <div>
    <h5>风格预测</h5>
    <div class="upload-wrapper">
      <input type="file" multiple @change="handleFileUpload">
      <img @click="uploadMusic" src="@/assets/images/update.png" id="updateImg">
    </div>
    
    <!-- 圆形框 @/assets/images/front.jpg -->
    <div class="image-wrapper" id="songDiv">
      <img src="@/assets/images/style/front.jpg" id="songImg">
    </div>

    <ul>
      <li v-for="(song, index) in songList" :key="index">
        <div class="song">
          <div class="song-info">
            <span class="song-index">{{index + 1}}</span>
            <img @click="loveOrNot(index)" class="song-img" :src="getImageFilename(loveMusicIndex[index])">
            <span class="song-name">{{song.name.replace('.mp3', '')}}</span>
          </div>
          <div class="song-custom-info">
            <span v-if="styleMap[index]">{{styleMap[index]}}</span>
            <span v-else>未知</span>
          </div>
          <div class="song-actions">
            <button class="play-button" @click="playSong(index)">Play</button>
          </div>
        </div>
      </li>
    </ul>
    <audio ref="audioPlayer"></audio>
    <div class="footerFill"></div>
  </div>
  <div class="footer">
    <footer class="footer-content">
      <div class="controls">
        <button @click="playPause" class="controlSong">
          {{ isPlaying ? "Pause" : "Play" }}
        </button>
        <button @click="nextSong" class="controlSong">Next</button>
      </div>
    </footer>
  </div>
</template>

<style>
  .upload-wrapper {
    display: flex;
    justify-content:center;
    align-items: center;
    height: 30px;
  }

  .image-wrapper {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden; /* 让超出圆形部分的图片不显示 */
    display: flex; /* 使用 flexbox 布局 */
    margin: auto;
  }

  .image-wrapper img {
    width: auto;
    height: 100%;
    object-fit: cover; /* 让图片填充满div */
  }

  .song {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  .song-info {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .song-index {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    background-color: #ff5722;
    color: #fff;
    border-radius: 50%;
    margin-right: 1rem;
  }

  .song-img {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 50%;
    margin-right: 1rem;
  }

  .song-name {
    flex: 1;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }

  .song-info-extra {
    font-size: 14px;
    color: #999;
    margin-left: auto;
  }

  .song-custom-info {
    margin-left: auto;
    width: 100px;
  }

  .play-button {
    background-color: #7fd1c7;
    border: none;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }

  .play-button:hover {
    background-color: #6bb7af;
  }

  .footerFill {
    width: 100%;
    height: 55.33px;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f8f8f8;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .controls {
    display: flex;
    gap: 10px;
  }

  .controlSong {
    background-color: #55c6a9;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  .rotate {
    animation: spin 60s linear infinite;
  }

  .rotateUpdate {
    animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      clickCount: 0,  // 表示预测按钮点击次数
      notPreIndex: [], // 未查找到的元素下标
      files: [], // 选中的文件数组
      nameKey: [],  // 音频文件名
      songList: [], // 播放列表
      currentSongIndex: 0,
      isPlaying: false,
      audioPlayer: null,
      styleMap: {},
      loveMusicIndex: {},  // 音乐列表是否为用户喜爱音乐
    };
  },
  methods: {
    // 处理文件上传事件
    handleFileUpload(event) {
      this.files = [];
      this.songList = [];
      this.clickCount = 0;
      this.notPreIndex = [];
      this.styleMap =  {};
      // 选中的文件数组
      const selectedFiles = event.target.files;
      // 文件类型
      const allowedTypes = ["audio/mpeg", "audio/mp3"];
      // 判断文件数量是否 <= 10
      if (selectedFiles.length <= 10) {
        const newFiles = [];
        // 遍历选中的文件数组，判断文件类型是否为 .mp3
        for (let i = 0; i < selectedFiles.length; i++) {
          if (!allowedTypes.includes(selectedFiles[i].type)) {
            alert("请选择 .mp3 格式的音乐文件！");
            return;
          }
          const mp3 = selectedFiles[i];
          this.nameKey[i] = mp3.name;
          newFiles.push(new File([mp3], i, { type: mp3.type, lastModified: mp3.lastModified}));
        }
        // 传递给播放列表和上传数组
        this.files = newFiles
        // 传递文件给播放列表
        this.songList = selectedFiles
        // 显示红心
        const token = localStorage.getItem('token');
        axios.post('api/ulm/showall', {}, {
          headers: {
            'Authorization': token,
          }
        })
        .then(response => {
          // 处理成功的情况
          const data = response.data;
          if (data.code === 200) {
            const musicList = data.data;
            // 喜爱音乐的音乐名
            const lovemusicName = [];
            for (let i = 0; i < musicList.length; i++) {
              lovemusicName.push(musicList[i]["musicName"]);
            }
            // 获取选择音乐是喜爱音乐的下标
            for (let key in this.nameKey) {
              const value = this.nameKey[key].replace(".mp3", "");
              if (lovemusicName.includes(value)) {
                this.loveMusicIndex[key] = 1;
              } else {
                this.loveMusicIndex[key] = 0;
              }
            }
          } else {
            // TODO：处理失败的逻辑
            alert('喜爱音乐获取失败：' + data.msg);
          }
        })
        .catch(error => {
          // TODO：处理失败的情况
          alert('请求失败：' + error);
        });
      } else {
        alert("最多只能选择 10 个文件！");
      }
    },
    // 执行上传操作
    uploadMusic() {
      const imgUpdate = document.querySelector('#updateImg');
      imgUpdate.classList.add('rotateUpdate');
      if (this.clickCount == 0) {
        const musicName = [];
        for (let key in this.nameKey) {
          musicName.push(this.nameKey[key]);
        }
        axios.post('api/music/batchClassification/selectStyle', musicName, {})
        .then(response => {
          // 处理成功的情况
          const data = response.data;
          if (data.code === 200) {
            this.styleMap = data.data;
            for (let key in this.styleMap) {
              if (this.styleMap[key] === "未知") {
                this.notPreIndex.push(key);
              }
            }
            if (this.notPreIndex.length == 0) {
              this.clickCount = 3;
              alert("所有音乐已经预测成功啦");
            } else if (this.notPreIndex.length < this.files.length) {
              alert("我们为您找到了部分音乐的音乐风格，剩下的可以继续点击风车按钮进一步预测哦");
            } else {
              alert("什么都没有找到，请再次点击风车按钮进一步预测哦");
            }
          } else {
            // TODO：处理失败的逻辑
            alert('失败：' + data.msg);
          }
          imgUpdate.classList.remove('rotateUpdate');
        })
        .catch(error => {
          // TODO：处理失败的情况
          imgUpdate.classList.remove('rotateUpdate');
          alert('请求失败：' + error);
        });
      } else if (this.clickCount == 1) {
        // 创建 FormData 对象
        const formData = new FormData();
        // 遍历选中的文件数组，添加到 FormData 对象中
        for (let i of this.notPreIndex) {
          formData.append('files', this.files[i]);
          formData.append('nameKey', this.nameKey[i]);
        }
        // 从 localStorage 中获取 token
        const token = localStorage.getItem('token');
        // 发送 POST 请求，上传文件
        axios.post('api/music/batchClassification/asyTask', formData, {
          headers: {
            'Authorization': token,
          }
        })
        .then(response => {
          // 处理成功的情况
          const data = response.data;
          if (data.code === 200) {
            alert('成功：' + data.data);
          } else {
            // TODO：处理失败的逻辑
            alert('失败：' + data.msg);
          }
          imgUpdate.classList.remove('rotateUpdate');
        })
        .catch(error => {
          // TODO：处理失败的情况
          imgUpdate.classList.remove('rotateUpdate');
          alert('请求失败：' + error);
        });
      } else {
        // 轮询
        // 从 localStorage 中获取 token
        const token = localStorage.getItem('token');
        // 发送 POST 请求，上传文件
        axios.post('api/music/batchClassification/forAsk', null, {
          headers: {
            'Authorization': token,
          }
        })
        .then(response => {
          // 处理成功的情况
          const data = response.data;
          if (data.code === 200) {
            let tempObj = {}
            tempObj = data.data;
            if (tempObj === null) {
              let flag = true;
              for (let key in this.styleMap) {
                flag = flag && (this.styleMap[key] !== "未知");
              }
              if (flag) {
                alert('已经处理成功了哦');
              } else {
                alert('正在加急处理中，请稍等哦');
              }
            } else {
              alert('处理成功啦！');
              let i = 0;
              for (let key in this.styleMap) {
                if (this.styleMap[key] === "未知") {
                  this.styleMap[key] = tempObj[i++];
                }
              }
            }
          } else {
            // TODO：处理失败的逻辑
            alert('失败：' + data.msg);
          }
          imgUpdate.classList.remove('rotateUpdate');
        })
        .catch(error => {
          // TODO：处理失败的情况
          imgUpdate.classList.remove('rotateUpdate');
          alert('请求失败：' + error);
        });
      }
      this.clickCount++;
    },
    // 音乐红心功能
    loveOrNot(index) {
      const token = localStorage.getItem('token');
      console.log(this.styleMap[index]);
      if (this.styleMap[index] === undefined || this.styleMap[index] === "未知") {
        alert("先点击风车按钮预测风格才能添加为喜爱哦");
        return;
      }
      if (this.loveMusicIndex[index] === 1) {
        const data = {
          code: 2,
          msg: 'cancleMusic',
          data: {
            musicName: this.nameKey[index].replace(".mp3", ""),
          }
        }
        axios.post('api/ulm/cancel', data, {
          headers: {
            'Authorization': token,
          }
        })
        .then(response => {
          // 处理成功的情况
          const data = response.data;
          if (data.code === 200) {
            if (data.data === 1) {
              this.loveMusicIndex[index] = 0;
            } else {
              alert("出错啦，请稍后重试。");
            }
          } else {
            alert('失败：' + data.msg);
          }
        })
        .catch(error => {
          // TODO：处理失败的情况
          alert('请求失败：' + error);
        });
      } else {
        const data = {
          code: 1,
          msg: 'loveMusic',
          data: {
            musicName: this.nameKey[index].replace(".mp3", ""),
          }
        }
        axios.post('api/ulm/love', data, {
          headers: {
            'Authorization': token,
          }
        })
        .then(response => {
          // 处理成功的情况
          const data = response.data;
          if (data.code === 200) {
            if (data.data === 1) {
              this.loveMusicIndex[index] = 1;
            } else {
              alert("出错啦，请稍后重试。");
            }
          } else {
            alert('失败：' + data.msg);
          }
        })
        .catch(error => {
          // TODO：处理失败的情况
          alert('请求失败：' + error);
        });
      }
    },
    // 更换红心图片
    getImageFilename(index) {
      if (index === 0) {
        return require("@/assets/images/01.png");
      } else if (index === 1) {
        return require("@/assets/images/02.png");
      }
      // 添加其他索引对应的图片文件名逻辑
    },
    // 播放音乐
    playSong(index) {
      this.currentSongIndex = index;
      const songUrl = URL.createObjectURL(this.songList[index]);
      this.audioPlayer.src = songUrl;
      this.audioPlayer.play();
      this.isPlaying = true;
      const imgDiv = document.querySelector('#songDiv');
      imgDiv.classList.add('rotate');
      const imgShow = document.getElementById('songImg');
      if (Object.keys(this.styleMap).length > 0) {
        if (this.styleMap[index] === "未知") {
          imgShow.src = require("@/assets/images/style/front.jpg");
        } else {
          const path = require("@/assets/images/style/" + this.styleMap[index] + ".png");
          imgShow.src = path;
        }
      }
    },
    // 播放暂停
    playPause() {
      if (this.isPlaying) {
        this.audioPlayer.pause();
        this.isPlaying = false;
        const imgDiv = document.querySelector('#songDiv');
        imgDiv.classList.remove('rotate');
      } else {
        this.audioPlayer.play();
        this.isPlaying = true;
        const imgDiv = document.querySelector('#songDiv');
        imgDiv.classList.add('rotate');
      }
    },
    // 下一首音乐
    nextSong() {
      this.currentSongIndex++;
      if (this.currentSongIndex >= this.songList.length) {
        this.currentSongIndex = 0;
      }
      this.playSong(this.currentSongIndex);
    },
  },
  mounted() {
    this.audioPlayer = this.$refs.audioPlayer;
  },
};
</script>
